<script>
    import { _, json } from "svelte-i18n";

    import Section from "../components/Section.svelte";
    import Card from "../components/Card.svelte";

    let projects = [
        {
            icon: "https://boukken.com/favicon.ico",
            banner_url: "https://boukken.com/background.png",
            href: "https://boukken.com",
            translation_key: "boukken",
        },
        {
            icon: "https://surprisequiz.com/favicon.ico",
            banner_url: "https://surprisequiz.com/background.jpg",
            href: "https://surprisequiz.com",
            translation_key: "surprisequiz",
        },
        {
            icon: "https://inkyhabit.com/favicon.ico",
            banner_url: "https://inkyhabit.com/placeholder.png",
            href: "https://inkyhabit.com",
            translation_key: "inky",
        },
        {
            icon: "https://sundayguesthouse.com/favicon.ico",
            banner_url: "https://sundayguesthouse.com/background.jpg",
            href: "https://sundayguesthouse.com",
            translation_key: "sundayguesthouse",
        },
        {
            icon: "https://misa-consult.com/favicon.ico",
            banner_url: "https://misa-consult.com/background.jpg",
            href: "https://misa-consult.com",
            translation_key: "misa",
        },
        {
            icon: "https://image.quantizebg.com/favicon.ico",
            banner_url: "https://image.quantizebg.com/background.jpg",
            href: "https://image.quantizebg.com",
            translation_key: "image_compressor",
        },
        {
            icon: "",
            banner_url: "",
            href: "https://quantizebg.com",
            translation_key: "coming",
            blur: true,
        },
    ];

    const onScrollClick = (direction) => () => {
        const container = document.getElementById("scroll-projects-container");

        const containerWidth = container.getBoundingClientRect().width;

        container.scrollLeft = container.scrollLeft + containerWidth * (direction === "left" ? -1 : 1);
    };
</script>

<Section id={"projects-section"} isFullHeight={true}>
    <h2>{$_("projects.header")}</h2>
    <div class="outer-wrapper">
        <div class="scroll-control left" on:click={onScrollClick("left")} on:keypress={onScrollClick("left")}>
            <span class="material-icons">chevron_left</span>
        </div>
        <div class="scroll-control right" on:click={onScrollClick("right")} on:keypress={onScrollClick("right")}>
            <span class="material-icons">chevron_right</span>
        </div>
        <div class="wrapper" id="scroll-projects-container">
            <div class="container" style="--width:{projects.length * 50 + '%'}">
                {#each projects as project}
                    <Card
                        width="50%"
                        icon_url={project.icon}
                        banner_url={project.banner_url}
                        blur={project.blur}
                        blur_text={$_("projects.coming-soon")}
                    >
                        <a href={project.href} target="_blank" slot="header">
                            <h2>
                                {$_(`projects.${project.translation_key}.title`)}
                            </h2>
                        </a>
                        <div slot="content">
                            {#each Object.values($_(`projects.${project.translation_key}.description`)) as text}
                                <p>{text}</p>
                            {/each}
                        </div>
                    </Card>
                {/each}
            </div>
        </div>
    </div>
</Section>

<style>
    .outer-wrapper {
        position: relative;
    }
    .scroll-control {
        position: absolute;
        width: 120px;
        top: 0;
        bottom: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .scroll-control span {
        font-size: 2.4rem;
    }
    .scroll-control.left {
        left: -120px;
    }
    .scroll-control.right {
        right: -120px;
    }
    .wrapper {
        position: relative;
        overflow-x: auto;
        scroll-behavior: smooth;
    }

    .container {
        position: relative;
        display: flex;
        justify-content: space-evenly;
        padding: 24px 1px;
        width: var(--width);
        overflow: hidden;
        border-radius: 6px;
    }

    .container a {
        color: white;
    }

    @media (min-width: 1140px) {
        .wrapper::-webkit-scrollbar {
            display: none;
        }
    }

    @media screen and (max-width: 1140px) {
        .container {
            width: unset;
            flex-direction: column;
        }
        .scroll-control {
            display: none;
        }
    }
</style>
